var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    VIP Services\n  ")]),
      !_vm.company?.domestic_registration
        ? _c(
            "b-form-group",
            { attrs: { label: "What is your company's home state?" } },
            [
              _c("company-domestic-registration-form", {
                attrs: { company: _vm.company, "load-products": true },
                on: { changed: _vm.domesticRegistrationChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.domesticJurisdiction && _vm.loaded
        ? _c(
            "div",
            [
              _vm.products.length > 0
                ? _c(
                    "b-list-group",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.products, function (bundle, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: index,
                          staticClass: "flex-column align-items-start",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex w-100 justify-content-between",
                            },
                            [
                              _c("h3", { staticClass: "mb-1" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.homeState) +
                                    " — " +
                                    _vm._s(bundle.name) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "ul",
                            { staticClass: "mb-3 border-bottom pt-3 pb-3" },
                            _vm._l(bundle.products, function (subProduct, i) {
                              return _c("li", { key: i }, [
                                _c("small", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(subProduct.name) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex w-100 justify-content-between mt-0",
                            },
                            [
                              _vm.products.length > 0
                                ? _c("div", { staticClass: "text-right" }, [
                                    _vm._v(
                                      "\n            Monthly cost for " +
                                        _vm._s(_vm.homeState) +
                                        " " +
                                        _vm._s(bundle.name) +
                                        ":\n            " +
                                        _vm._s(
                                          _vm._f("dollars")(
                                            bundle.monthly_amount
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    "aria-label": "add to cart button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.addToCart(_vm.productData(bundle))
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Add to Cart\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _c("div", [
                    _vm._v(
                      "\n      There are currently no VIP Services available for this company's home state (" +
                        _vm._s(_vm.homeState) +
                        ")\n      that you have not already purchased\n    "
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.loaded ? _c("ct-centered-spinner") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }