<template>
  <!--  TODO: This component is not yet being used, but will probably be in the future.-->
  <!--  If so, this component needs to be made aware of checkoutDomesticJurisdiction so that-->
  <!--  it can function within the updated HireUs contextual jurisdiction flow.-->
  <div>
    <h3>
      VIP Services
    </h3>
    <b-form-group
      v-if="!company?.domestic_registration"
      label="What is your company's home state?"
    >
      <company-domestic-registration-form
        :company="company"
        :load-products="true"
        @changed="domesticRegistrationChanged"
      />
    </b-form-group>

    <div v-if="domesticJurisdiction && loaded">
      <b-list-group v-if="products.length > 0" class="mt-2">
        <b-list-group-item
          v-for="(bundle, index) in products"
          :key="index"
          class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">
              {{ homeState }} &mdash; {{ bundle.name }}
            </h3>
          </div>
          <ul class="mb-3 border-bottom pt-3 pb-3">
            <li v-for="(subProduct, i) in bundle.products" :key="i">
              <small>
                {{ subProduct.name }}
              </small>
            </li>
          </ul>
          <div class="d-flex w-100 justify-content-between mt-0">
            <div v-if="products.length > 0" class="text-right">
              Monthly cost for {{ homeState }} {{ bundle.name }}:
              {{ bundle.monthly_amount | dollars }}
            </div>
            <b-button
              variant="primary"
              aria-label="add to cart button"
              @click="addToCart(productData(bundle))"
            >
              Add to Cart
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <div v-else>
        There are currently no VIP Services available for this company's home state ({{ homeState }})
        that you have not already purchased
      </div>
    </div>
    <ct-centered-spinner v-if="!loaded" />
  </div>
</template>

<script>


// TODO: This component is not yet being used, but will probably be in the future.
// See note in template for information.



import { dollars } from '@/filters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SubscriptionBundleCheckout',
  filters: {
    dollars,
  },
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    CompanyDomesticRegistrationForm: () => import('@/components/HireUs/CompanyDomesticRegistrationForm'),
  },
  props: {
    product: Object,
    company: Object,
  },
  data() {
    return {
      loaded: false,
      domesticJurisdiction: null,
      products: [],
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'findCartItem',
      'cartItems',
      'subscriptionBundlesByJurisdiction',
    ]),
    ...mapGetters('companies', [
      'activeSubscriptions',
      'domesticRegistration',
      'checkoutDomesticJurisdiction',
    ]),

    existingCartItems() {
      return this.cartItems.filter(i => i.product_id === this.product.id)
    },

    homeState() {
      return this.domesticJurisdiction.state_province_region

      // TODO implement domesticRegistration and checkoutDomesticJurisdiction getters.
      // return this.domesticRegistration?.jurisdiction?.state_province_region
      //   || this.checkoutDomesticJurisdiction?.state_province_region
    },
  },
  async mounted() {
    this.loaded = false
    if (this.company.domestic_registration) {
      this.domesticJurisdiction = this.company.domestic_registration.jurisdiction
    }

    if (!this.product && this.domesticJurisdiction) {
      this.products = this.subscriptionBundlesByJurisdiction(this.domesticJurisdiction)
    } else {
      this.products = [this.product]
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('checkout', ['addToCart', 'removeFromCart']),
    ...mapActions('companies', ['loadActiveSubscriptions']),

    domesticRegistrationChanged(oldDomesticJurisdiction, newDomesticJurisdiction) {
      this.products             = this.subscriptionBundlesByJurisdiction(newDomesticJurisdiction)
      this.domesticJurisdiction = newDomesticJurisdiction
    },
    alreadyAdded() {
      //TODO: Add a remove button
    },
    productData(bundle) {
      return { ...bundle, skipResolveOptionalItems: true }
    },
  },
}
</script>
